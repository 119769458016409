import { set } from 'lodash';

const parseReason = function (message) {
    const regexBecause = /because \[(.*?)(\]|$)/;
    let parsedMessage = message;

    if (regexBecause.test(message)) {
        parsedMessage = regexBecause.exec(message)[1];
    }

    if (regexBecause.test(parsedMessage)) {
        parsedMessage = parseReason(parsedMessage);
    }

    return parsedMessage;
};

const parseValidation = function (response) {
    let validation = response && response.validation;
    const message = response && response.message;
    const error = response && response.error;
    const data = response && response.data;
    const statusCode = response && response.statusCode;
    const result = {
        error,
        errorMessage: message,
        statusCode: statusCode,
        hasError: {},
        help: {}
    };

    if (data && data.validation && !validation) {
        validation = data.validation;
    }

    if (validation && validation.keys && validation.keys.length > 0) {
        const errorMessages = message.split('. ');
        for (let i = 0; i < validation.keys.length; ++i) {
            const forField = validation.keys[i];
            const errorMessage = errorMessages[i];

            const reason = parseReason(errorMessage);

            set(result.hasError, forField, true);
            set(result.help, forField, reason);
        }
    }

    return result;
};

export default parseValidation;
