import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Terms from './pages/Terms/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import SignUp from './pages/SignUp/SignUp';
import Pricing from './pages/Pricing/Pricing';

import './app.scss';

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route
            path='/login/reset/:email/:token'
            element={<ResetPassword  {...this.props}/>}
          />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='*' element={<PageNotFound />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
