import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

import { signUp } from '../../actions/accountActions';
import parseValidation from '../../helpers/parse-validation';

const SignUp = (props) => {
    const [data, setData] = useState({
        companyName: '',
        adminName: '',
        adminEmail: '',
        password: '',
        passwordRepeat: '',
        phoneNumber: '',
        payPeriodStartDate: '',
        payPeriodEndDate: '',
        logo: null
    });
    const [errors, setErrors] = useState({
        companyName: '',
        adminName: '',
        adminEmail: '',
        password: '',
        passwordRepeat: '',
        payPeriod: ''
    });
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Something went wrong, please try again');
    const [loading, setLoading] = useState(false);

    const handleFieldChange = (ev) => {
        setData({
            ...data,
            [ev.target.id]: ev.target.value
        });

        setErrors({
            ...errors,
            [ev.target.id]: ''
        });
    };

    const handleLogoSelect = (ev) => {
        setData({
            ...data,
            logo: ev.target.files[0]
        });
    };

    const validateData = () => {
        const { password, passwordRepeat, payPeriodEndDate, payPeriodStartDate } = data;
        let isValid = true;
        if (password !== passwordRepeat) {
            setErrors({
                ...errors,
                passwordRepeat: 'Please make sure both passwords are match'
            });
            isValid = false;
        } else if (
            new Date(payPeriodEndDate) > new Date() ||
            new Date(payPeriodStartDate) > new Date()
        ) {
            setErrors({
                ...errors,
                payPeriod: 'Please select a date range in the past'
            });
            isValid = false;
        }

        return isValid;
    };

    const handleFormSubmit = (ev) => {
        ev.preventDefault();

        if (validateData()) {
            setLoading(true);
            signUp(data).then(({ response, data }) => {
                setLoading(false);
                window.scrollTo(0, 0);
                if (response && response.ok) {
                    setSuccess(true);
                } else if (response && response.status === 400) {
                    // check fields validation
                    const validation = parseValidation(data);
                    setErrors({
                        ...errors,
                        ...validation.help
                    });
                    setError(true);
                    setErrorMessage('Please fix all errors below');
                } else {
                    console.log(response);
                    setError(true);
                }
            });
        }
    };

    let form = null;
    let successConfirmation = null;

    if (success) {
        successConfirmation = (
            <div className="confirmation">
                <h1>Well done!</h1>
                <p>Your free trial period successfully started. Please, log in.</p>
                <div className="btns-wrapper">
                    <Link to="/" className="btn primary">
                        Go Home
                    </Link>
                </div>
            </div>
        );
    } else {
        form = (
            <>
                <form className="form" onSubmit={handleFormSubmit}>
                    <h2>Create Trial Account</h2>
                    {error && (
                        <div className="error-wrapper">
                            <span style={{ color: 'red' }}>{errorMessage}</span>
                        </div>
                    )}
                    <div className="fieldset">
                        <label htmlFor="companyName" className={errors.companyName ? 'error' : ''}>
                            Company Name*
                        </label>
                        <input
                            type="text"
                            id="companyName"
                            className={errors.companyName ? 'error' : ''}
                            value={data.companyName}
                            onChange={handleFieldChange}
                        />
                        {errors.companyName && <span className="error">{errors.companyName}</span>}
                    </div>
                    <div className="fieldset">
                        <label htmlFor="adminName" className={errors.adminName ? 'error' : ''}>
                            Admin Name*
                        </label>
                        <input
                            type="text"
                            id="adminName"
                            className={errors.adminName ? 'error' : ''}
                            value={data.adminName}
                            onChange={handleFieldChange}
                        />
                        {errors.adminName && <span className="error">{errors.adminName}</span>}
                    </div>
                    <div className="fieldset">
                        <label htmlFor="adminEmail" className={errors.adminEmail ? 'error' : ''}>
                            Admin Email*
                        </label>
                        <input
                            type="text"
                            id="adminEmail"
                            className={errors.adminEmail ? 'error' : ''}
                            value={data.adminEmail}
                            onChange={handleFieldChange}
                        />
                        {errors.adminEmail && <span className="error">{errors.adminEmail}</span>}
                    </div>
                    <div className="fieldset">
                        <label htmlFor="password" className={errors.password ? 'error' : ''}>
                            Password*
                        </label>
                        <input
                            type="password"
                            id="password"
                            className={errors.password ? 'error' : ''}
                            value={data.password}
                            onChange={handleFieldChange}
                        />
                        {errors.password && <span className="error">{errors.password}</span>}
                    </div>
                    <div className="fieldset">
                        <label
                            htmlFor="passwordRepeat"
                            className={errors.passwordRepeat ? 'error' : ''}
                        >
                            Password Repeat*
                        </label>
                        <input
                            type="password"
                            id="passwordRepeat"
                            className={errors.passwordRepeat ? 'error' : ''}
                            value={data.passwordRepeat}
                            onChange={handleFieldChange}
                        />
                        {errors.passwordRepeat && (
                            <span className="error">{errors.passwordRepeat}</span>
                        )}
                    </div>
                    <div className="fieldset">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            value={data.phoneNumber}
                            onChange={handleFieldChange}
                        />
                    </div>
                    <div className="fieldset">
                        <label>Logo</label>
                        <input type="file" className="btn" id="logo" onChange={handleLogoSelect} />
                    </div>
                    <div className="two-col">
                        <div className="fieldset">
                            <label htmlFor="payPeriodStartDate">Pay period Start Date</label>
                            <input
                                type="date"
                                id="payPeriodStartDate"
                                className={errors.payPeriod ? 'error' : ''}
                                onChange={handleFieldChange}
                            />
                        </div>

                        <div className="fieldset">
                            <label htmlFor="payPeriodEndDate">Pay period End Date</label>
                            <input
                                type="date"
                                className={errors.payPeriod ? 'error' : ''}
                                id="payPeriodEndDate"
                                onChange={handleFieldChange}
                            />
                        </div>
                    </div>
                    {errors.payPeriod && (
                        <span className="error">{errors.payPeriod}</span>
                    )}
                    <div className="btns-wrapper">
                        <button type="submit" className="btn primary full-width" disabled={loading}>
                            start free trial
                        </button>
                    </div>
                </form>
                <p className="notes">
                    With the trial account this will give your company full access for 3 weeks
                </p>
            </>
        );
    }

    return (
        <>
            <Header />
            <main>
                <section className="center">
                    <div className="container">
                        <div className="form-wrapper">
                            {form}
                            {successConfirmation}
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    );
};

export default SignUp;
