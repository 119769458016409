import { getDxpUrl } from '../helpers/utils';

function getDxpApiUrl() {
    return `${getDxpUrl()}/api`;
}

export function resetPassword(data = {}) {
    const { resetKey, resetEmail, password, passwordRepeat } = data;
    let url = `${getDxpApiUrl()}/login/reset?access_token=${process.env.REACT_APP_DXP_API_KEY}`;
    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            key: resetKey,
            email: resetEmail,
            password: password,
            passwordRepeat: passwordRepeat
        })
    })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.log(err);
        });
}

export function signUp(data = {}) {
    let url = `${getDxpApiUrl()}/time-tracking/sign-up`;

    const formData = new FormData();
    for (const dataKey of Object.keys(data)) {
        if ((dataKey === 'logo' && data[dataKey]) || dataKey !== 'logo') {
            formData.append(dataKey, data[dataKey]);
        }
    }

    return fetch(url, {
        method: 'POST',
        body: formData
    })
        .then((response) => {
            return new Promise((resolve) => {
                response.json().then((data) => {
                    resolve({ response, data })
                })
            });
        })
        .catch((error) => {
            console.log(error);
            return { error }
        });
}
