import React, { Component } from 'react';
import Header from '../../components/header/Header';
class Terms extends Component {

  render() {
    return (
      <>
        <Header />
        <main className='single-page'>
          <section className='terms'>
            <div className='container'>
              <h1>
                Trades2Work Terms of Service, Cookie Policy &amp;
                Privacy Statement
              </h1>
              <p>
                Trades2Work Inc ("Company", "we", "us", or "our") is focused on
                making the Trades2Work service the safest and best possible
                experience for you and your end-users ("you", "your"). We have
                great respect for people's personal privacy, and we consider
                this a matter of great importance. In order to provide you with
                a useful service when you are viewing our content or using our
                services; we need some data from you in order to do so;
                otherwise we cannot provide you with a useful service. Our
                commitment to you is that we will use our best efforts to ensure
                any information submitted to, or gathered by us; remains
                private, and is only used for the purposes set forth in this
                Statement.
              </p>
              <p>
                This page states the COMPANY Privacy Statement (herein the
                "Statement") and sets forth the collection, use, and disclosure
                practices that apply to the COMPANY Website, COMPANY Software,
                COMPANY Services, and COMPANY Products (herein "Services"). The
                Statement also incorporates by reference the COMPANY Terms of
                Service for the Trades2Work Experience, which apply to this
                statement. By using the Experience; you explicitly accept,
                without limitation or qualification; the collection, use, and
                disclosure of personal information provided by you in the manner
                described in this Statement. Please read the Statement carefully
                as it affects your rights and liabilities. If you disagree with
                the way we collect and process personal information, please do
                not use theTrades2Work Service.
              </p>
              <h2>What Information Do We Collect?</h2>
              <h3>Information You Submit</h3>
              <p>
                When you request information, use the service, register as a
                user, respond to an online survey, or otherwise actively send us
                data; we may collect data including, but not limited to; your
                name, email address(es), mailing address(es), telephone
                number(s), and information related to the business you
                represent. If the product or service provided is something we
                charge for, we will also collect payment information; such as
                credit card numbers, bank account details and related
                verification information, phone number, email address, and
                billing / shipping addresses; to process the payment. In all the
                above cases, you will know exactly what data we collect through
                the Site or mobile app, because you actively submit it to us.
              </p>
              <h3>Information We Gather</h3>
              <p>
                As part of the standard operation of the Site or mobile app, we
                may collect information from your computer or device; including
                but not limited to your browser type, device type, operating
                system, IP address, and the domain name from which you accessed
                the Site or mobile app. Additionally, we may collect behavioral
                information about your use of the Site or mobile app; this
                includes but is not limited to; the date and time you visit the
                Site or mobile app, actions taken while viewing information on
                the Site or mobile app, which areas or pages of the Site or
                mobile app you visit or view, the amount of time you spend
                viewing the Site or mobile app, the number of times you return
                to the Site or mobile app, and other such analytics data. We may
                also collect similar data regarding your usage of our Services.
                We also may acquire personal information from third parties who
                provide it to us.
              </p>
              <h3>How Do We Use the Information?</h3>
              <p>
                For the purposes of this Statement, the terms "using" and
                "processing" of information includes; the use of cookies,
                analyzing information through various methods, other means of
                storage on a computer or mobile device, and also the collecting,
                storing, evaluating, modifying, deleting, using, combining,
                disclosing and transferring information; within our organization
                or among our subsidiaries within the European Union, the United
                States, or internationally.
              </p>
              <h3>Personal Information</h3>
              <p>
                We use Personal Information for only the following purposes: (1)
                to operate the Services, (2) account verification, (3) to
                fulfill service requests, (4) to improve and optimize the
                Service, (5) to personalize your experience, (6) responding to
                inquiries or providing support, (7) to provide software updates
                and product announcements, (8) to better understand your needs
                and interests, and (9) to provide you with further information
                and offers from us that we believe you may find useful or
                interesting, such as targeted advertising or promotional
                campaigns.
              </p>
              <h3>Non-Personally Identifiable Information</h3>
              <p>
                We may use Non-Personally Identifiable Information for the
                following purposes: (1) to analyze trends and usage of resources
                through aggregated data, (2) to improve the features and
                functionality of our Services, (3) to better understand your
                needs and interests, and (4) to better understand the needs and
                interests of End-Users. We may also share Non-Personally
                Identifiable Information from End-Users with our Trades2Work
                Account Holders, to allow them to gain more value, or to provide
                more insight into end-users. In this context, any information
                shared with Account Holders; will not contain or be linked to
                your Personal Information; unless you have given us your
                explicit consent, or the end-user has given us their explicit
                consent. For example, a Trades2Work Account Holder may want to
                better understand how to create a compelling and non-intrusive
                experience for end-users, and COMPANY may provide aggregated
                information to show examples of practices that worked well, and
                practices that did not work well.
              </p>
              <h3>Cookies and Other Technologies</h3>
              <p>
                A cookie is a small text file that may be stored on the hard
                drive of your computer or device when you access the Site or
                mobile app. We may use cookies and/or other storage on your
                device to help us collect, maintain, and use this information,
                for the following purposes: (1) personalize your experience and
                provide you with customized content, (2) monitor Site and
                Account usage to better understand how you interact with our
                Services, (3) remember you when you return to the Site, and (4)
                conduct research to improve our content and services. You may
                refuse to accept cookies, but by doing so you may not be able to
                use certain features on the Site or mobile app or take full
                advantage of all of our offerings. All web browsers offer you
                some form of ability to manage your cookie preferences.
              </p>
              <p>
                We may also use a third-party analytics partner, who may employ
                some form of software technology to help us better manage
                content on our Site; by informing us about what content is
                effective. The information gathered by third-party analytics
                software is not tied to Personally Identifiable Information;
                however, the use of cookies and other technologies by our
                partners and service providers is not covered by our privacy
                statement. We do not have access or control over these
                third-party cookies and technologies.
              </p>
              <p>
                Our Site or mobile application may also include social media
                features, such as, but not limited to the Facebook "Like" button
                and Twitter "Tweet" button. These features may collect your IP
                address and page visits; and may set a cookie to enable their
                feature to function properly. Social media features are either
                hosted by a third party or hosted directly on our Site or mobile
                app. Your interactions with these features are governed by the
                privacy Statement of the company providing it.
              </p>
              <h3>Location Data</h3>
              <p>
              Trades2Work collects location data to enable the clocking in and 
              out function, getting the weather for reports,  even when the app is 
              closed or not in use while clocked in.
              </p>
              <h2>When Do We Share or Disclose Information?</h2>
              <p>
                We may disclose or share information collected from you and
                about you; to the following entities; for the following
                purposes: (1) to our service providers and suppliers, if such
                disclosure enables any of them to perform a business,
                professional, or technical support function for us, for example
                to process payments, (2) as necessary if we believe that there
                has been a Statement violation such as those published on our
                site, or some violation of terms you signed, or some other
                violation of our rights or the rights of any third party, (3) to
                respond to judicial process and provide information to law
                enforcement agencies, or in connection with an investigation on
                matters related to public safety, as permitted by or otherwise
                required by law, and (4) otherwise with your consent.
                Additionally, in the event of a merger, acquisition,
                reorganization, or sale of our entity or our assets, or in the
                event of our bankruptcy; your personal information may be one of
                the transferred or acquired assets. We may also share your
                personal information where you have granted us permission to do
                so, and we reserve the right to use and disclose any information
                collected via the Site that is not in a Personally Identifiable
                form.
              </p>
              <h2>How Can I Update My Personal Information?</h2>
              <p>
                You may review, update, correct any Personal Information in your
                Trades2Work Account by contacting us, or by making the
                appropriate modifications in your Account profile or
                preferences.
              </p>
              <h2>Is My Information Secure?</h2>
              <p>
                Security is of paramount importance to us, and we work hard to
                ensure that our Site and Services are as secure as is possible.
                We have taken certain physical, electronic, contractual, and
                managerial steps to safeguard and secure the information we
                collect from Site visitors and Service users. However, no method
                of transmission over the Internet, or method of electronic
                storage, is ever 100% secure; therefore while we endeavor to use
                commercially acceptable means to protect your personal
                information, we cannot guarantee its absolute security. If you
                register on our Site or use our Services, it is your
                responsibility to protect the security of your Account, and any
                end-users you are providing services to through our Services and
                your Account.
              </p>
              <h2>Links, and Third-Party Sites and Services</h2>
              <p>
                The Site may contain links to Third-Party Websites, and some
                Third-Parties may use our Services to provide services to
                end-users. Third-Parties may have privacy policies that differ
                from our own, and we are not responsible for the activities and
                practices that take place on these sites; or any services
                provided through our Services. We therefore encourage you to
                review the privacy Statement posted on the Third-Party Website;
                should you access them through the Site, or through our
                Services. The fact that we may link to or incorporate a
                third-party's services, functionality, or website; or present
                some type of advertisement; is not an endorsement,
                authorization, or representation of our affiliation with that
                third-party; nor is it an endorsement of their privacy or
                information security policies or practices. We do not exercise
                control over Third-Party Websites, or any third-party services
                provided through our Services; including their use of personal
                information. Our Privacy Statement only applies to our Site and
                Services, and we are not responsible for the privacy practices
                or content of other websites, or third-party services provided
                through our Services.
              </p>
              <h2>General</h2>
              <h3>Information Retention</h3>
              <p>
                We will retain your information for as long as your Account is
                active, or as is needed to provide you with our Services. We
                will retain and use your information as necessary to comply with
                our legal obligations, resolve disputes, and enforce our
                agreements. Additionally, we will archive information after it
                is no longer useful for providing our Services.
              </p>
              <h3>Children's Information</h3>
              <p>
                The Site and our Services are not directed to children under the
                age of 16, nor do we knowingly collect any personal information
                from them.
              </p>
              <h3>Statement Agreement</h3>
              <p>
                By using any part of the Site or our Services, you agree to the
                current Privacy Statement and Terms of Service to which this
                Privacy Statement is associated.
              </p>
              <h3>Changes to This Statement</h3>
              <p>
                We reserve the right to make changes this privacy Statement from
                time to time at our sole discretion. When we do, we will post
                the change(s) on our website.
              </p>
            </div>
          </section>
          <div className='footer'>
            <p>©2022 Trades2work. All rights reserved.</p>
          </div>
        </main>
      </>
    );
  }
}

export default Terms;
