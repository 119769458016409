import React from 'react';

import './modal.scss';

const Modal = ({ isVisible, onClose }) => {
    const handleCloseModal = () => {
        onClose();
    };

    return (
        <div className={`modal ${isVisible ? 'show' : ''}`} onClick={handleCloseModal}>
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Dmw9NHjDQXs?rel=0"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
};

export default Modal;
