import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <p>©2022 Trades2work. All rights reserved.</p>
        </div>
    );
};

export default Footer;
