import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import Header from '../../components/header/Header';
import { resetPassword } from '../../actions/accountActions';

const ResetPassword = props => {
    const { email, token } = useParams();
    const [password, setPassword] = useState(null);
    const [passwordRepeat, setPasswordRepeat] = useState(null);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [passwordResetError, setPasswordResetError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const submit = () => {
        resetPassword({
            resetKey: token,
            resetEmail: email,
            password,
            passwordRepeat
        }).then(r => {
            if (r.status === 200) {
                setPasswordResetSuccess(true);
            } else {
                setPasswordResetError(true);
            }
        });
    };
    const validatePassword = () => {
        if (!password || !passwordRepeat || password !== passwordRepeat) {
            setPasswordError(true);
        } else {
            submit();
        }
    };

    return (
        <>
            <Header />
            <main>
                <section className="reset-password center">
                    <div className="container">
                        <div className="form-wrapper">
                            {!passwordResetSuccess ? (
                                <div className="form">
                                    <h2>Reset your password</h2>
                                    {passwordResetError ? (
                                        <label htmlFor="repeatNewPassword" style={{ color: 'red' }}>
                                            Invalid Email or Token
                                        </label>
                                    ) : null}
                                    {passwordError ? (
                                        <label htmlFor="repeatNewPassword" style={{ color: 'red' }}>
                                            Please make sure both passwords are filled out, and
                                            match
                                        </label>
                                    ) : null}

                                    <div className="fieldset">
                                        <label htmlFor="newPassword">New Password</label>
                                        <input
                                            type="password"
                                            onChange={e => setPassword(e.target.value)}
                                            id="newPassword"
                                        />
                                        <i className="input-icon eye" />
                                    </div>
                                    <div className="fieldset">
                                        <label htmlFor="repeatNewPassword">
                                            Repeat New Password
                                        </label>
                                        <input
                                            type="password"
                                            onChange={e => setPasswordRepeat(e.target.value)}
                                            id="repeatNewPassword"
                                        />
                                        <i className="input-icon eye-off" />
                                    </div>
                                    <div onClick={e => validatePassword()} className="btns-wrapper">
                                        <p className="btn primary full-width">Set password</p>
                                    </div>
                                </div>
                            ) : (
                                <div className="confirmation">
                                    <h1>Well done!</h1>
                                    <p>Your password was changed successfully. Please, sign in.</p>
                                    <div className="btns-wrapper">
                                        <Link to="/" className="btn primary">
                                            Go Home
                                        </Link>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default ResetPassword;
