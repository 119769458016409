import React, { Component } from 'react';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';

class Pricing extends Component {
    render() {
        return (
            <>
                <Header />
                <main className="single-page">
                    <section className="pricing">
                        <div className="container">
                            <h1>
                                <span>pricing</span>
                                The right plan for your needs
                            </h1>
                            <div className="pricing-wrapper">
                                <div className="pricing-block">
                                    <h2>Basic</h2>
                                    <p>Time tracking</p>
                                    <h3>$10</h3>
                                    <div href="#" className="btn primary">
                                        buy now
                                    </div>
                                    <hr />
                                    <span>
                                      <b>This gives access to:</b>
                                      <ul>
                                          <li>Clocking in and out </li>
                                          <li>Time Card Modifications</li>
                                          <li>Paid Time Off </li>
                                          <li>Employee Handbook</li>
                                      </ul>
                                    </span>
                                </div>
                                <div className="pricing-block">
                                    <h2>Standard</h2>
                                    <p>Reporting requires time tracking</p>
                                    <h3>$10</h3>
                                    <div href="#" className="btn primary">
                                        buy now
                                    </div>
                                    <hr />
                                    <span>
                                      <b>This gives access to:</b>
                                      <ul>
                                          <li>Daily reporting</li>
                                          <li>Accident Reporting</li>
                                          <li>Toolboxtalks </li>
                                          <li>Injury Reporting</li>
                                      </ul>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer/>
                </main>
            </>
        );
    }
}

export default Pricing;
